@import 'colors';
@import 'layout';
@import 'transformations';
@import 'typography';
@import '~react-toastify/scss/main';

.clickable {
  cursor: pointer;
}

.list--borderless.list-group > .list-group-item {
  border-width: 0;
}

.OT_root {
  &.OTSubscriberContainer {
    padding-top: 75%;
  }

  .OT_widget-container {
    top: 0;
  }
}

.list-group-item {
  outline: 0;
}

.Toastify__toast-body {
  white-space: pre-line;
}
.Toastify__toast--info {
  a, a:hover, a:visited, a:active {
    color: white;
    text-decoration: underline;
  }
}

.youtubeContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.youtubeContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.media--video {
  width: 100%;
  position: relative;
  & > svg {
    position: absolute;
    top: 44%;
    left: 44%;
    width: 12%;
    height: 12%;
    fill: #fff;
    -webkit-filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .5));
    filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .5));
    opacity: 0.6;
  }
  & > img {
    width: 100%;
  }
}

.bid-history__item {
  border: 0;
}

.auction__actions {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 1001;
}

#list__upcoming div.upcoming__list--header + .lot-list--item.list-group-item {
  border-top-width: 0;
}

.iiz__zoom-img--visible {
  min-width: 100%;
}

.listings__carousel--active {
  outline: 3px solid $rt-color-warning;
}

.listing__card--partial > div {
  height: 100%;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.ratio-container .img-responsive.lazy.lazyloading,
button .img-responsive.lazy.lazyloading,
.iiz > div {
  width: 100%;
  height: 100%;
}

.iiz > div,
.img-responsive.lazy {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
}

.iiz > div,
.img-responsive.lazy:not(.lazyloaded) {
  animation: 1.5s shine linear infinite;
}

.img-responsive.lazy {
  width: 100%;
  &.active {
    outline: 3px solid $rt-color-info;
  }
  &.lazyload {
    width: 100%;
  }
}

.modal__icon {
  font-size: 6rem;
  &--danger {
    color: $rt-color-error;
  }
  &--info {
    color: $rt-color-info;
  }
  &--success {
    color: $rt-color-success;
  }
  &--warning {
    color: $rt-color-warning;
  }
}

.image-gallery-icon--disabled {
  opacity: .6;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%) scale(1);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #fff;
  padding: 0;
  transition: all 0.3s;
  &:hover:not(:disabled) {
    transform: translateY(-50%) scale(1.4);
  }
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .5));
  filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .5));
}

.embla__button--prev {
  left: 2rem;
}

.embla__button--next {
  right: 2rem;
}

.timed-auction__filters--state .filters__state {
  border-color: #ddd;
  color: #777;
}

.page-item-count__input {
  width: auto;
  display: inline-block;
}

.filters__badge {
  margin-right: -0.5rem;
  border-radius: 50%;
}

.auction__results-count {
  font-weight: bold;
}

.filters__container {
  @media screen and (max-width: 576px) {
    text-align: center;
  }
  @media screen and (min-width: 576px) {
    text-align: right;
  }
}
